import './App.css';

function App() {
  return (
    <div className="App">
      <p>Please Stay tuned to TG for re-launch & migration announcement: <a href="https://t.me/LuigiInuBSC">t.me/LuigiInuBSC</a></p>
      <p>💚💚💚</p>
    </div>
  );
}

export default App;
